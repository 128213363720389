import ToggleTarget from "./ToggleTarget";

const btn = document.querySelector('.js-SearchBtn');

if( btn ) {
  new ToggleTarget(btn, {
    breakpoint: "large",
    mediaQuery: 'min-width',
    closeOnOutsideClick: true,
    focusLock: true,
    onShow: (tt) => {
      setTimeout(() => {
        tt.target.querySelector(['[type="search"]']).focus();
      }, 100)
    }
  })
}
