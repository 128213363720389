
const container = document.querySelector('.js-FloatingFeedback');

const floatingFeedback = () => {
  const btn = document.querySelector('.js-ActivateFeedback');

  function show() {
    container.removeAttribute('hidden');
  }
  
  function hide() {
    container.setAttribute('hidden', 'hidden');
  }
  
  if(container) {
    setTimeout(() => {
      show();
    }, 3000)
  
    container.addEventListener('click', e => {
      if(e.target.closest('[data-action="close"]')) {
        hide();
      }
    });
  
    if(btn) {
      btn.addEventListener('click', e => show());
    }  
  }
}

if( container ) {
  floatingFeedback();
}