// Automatically parse string based numbers and objects into correct types
export default function objectParser(obj){
  const newObj = { ...obj };

  Object.keys(newObj).forEach(key => {
    try {
      newObj[key] = JSON.parse(newObj[key]);
    } catch {}
  });

  return newObj;
}