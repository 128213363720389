import ToggleTarget from "./ToggleTarget";

const navToggle = document.querySelector('.js-NavToggle');
const menuToggles = document.querySelectorAll('.js-NavMenuToggle');
const menuLaunchers = document.querySelectorAll('.js-MenuLauncher');
const menus = [];

if( navToggle ) {
  const nav = new ToggleTarget(navToggle, {
    breakpoint: 'large',
    mediaQuery: 'max-width',
    focusLock: true,
    isExpanded: false,
    bodyClass: 'u-scroll-lock',
    onShow: () => {
      window.scrollTo(0, 0);
    },
    onHide: () => {
      menus.forEach(menu => {
        menu.hide();
      })
    }
  });

  menuToggles.forEach(toggle => {
    const menu = new ToggleTarget( toggle, {
      breakpoint: 'large',
      mediaQuery: 'max-width',
      focusLock: true,
      isExpanded: false,
      onHide: (instance) => {
        instance.target.classList.add('has-back');
      }
    });
    
    const closeBtn = menu.target.querySelector('.js-NavMenu-back');
    
    if(closeBtn) {
      closeBtn.addEventListener('click', e => {
        menu.hide();
      });
    }
    
    menus.push(menu);
  });

  menuLaunchers.forEach(btn => {
    btn.addEventListener('click', e => {
      const targetId = e.target.getAttribute('aria-controls');
      const menu = menus.find(menu => menu.target.id === targetId);

      nav.show();

      if(menu) {
        menu.target.classList.remove('has-back');
        menu.show();
      }
    });
  });
}