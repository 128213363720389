import addPrefetch from "./add-prefetch";

function generateEmbed(videoId, params) {
  const playerParams = new URLSearchParams(params).toString();
  const parser = new DOMParser();
  const iframeString = `
    <iframe width="640" height="360" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
      src="https://player.vimeo.com/video/${videoId}?autoplay=1&${playerParams}"
    ></iframe>
  `;
  const iframe =  parser.parseFromString(iframeString, "text/html");
  
  return iframe.body.firstChild;
}

function warmConnections() {
  if (window.vimeoPreconnected) return;

  // The iframe document and most of its subresources come right off player.vimeo.com
  addPrefetch('preconnect', 'https://player.vimeo.com');
  // Images
  addPrefetch('preconnect', 'https://i.vimeocdn.com');
  // Files .js, .css
  addPrefetch('preconnect', 'https://f.vimeocdn.com');
  // Metrics
  addPrefetch('preconnect', 'https://fresnel.vimeocdn.com');

  window.vimeoPreconnected = true;
}

function play( iframe ) {
	iframe.contentWindow.postMessage({
    'method': 'play'
  }, iframe.src);
};

function pause( iframe ) {
	iframe.contentWindow.postMessage({
    'method': 'pause'
  }, iframe.src);
};

export { generateEmbed, warmConnections, play, pause };