document.addEventListener('DOMContentLoaded', () => {
    // Get reference to the clear all checkbox
    var clearAllCheckbox = document.getElementById('show-all');

    // Add event listener to the clear all checkbox if it exists
    if (clearAllCheckbox) {
        clearAllCheckbox.addEventListener('change', function () {
            // Get the parent CheckboxList
            var checkboxList = this.closest('.CheckboxList');

            if (checkboxList) {
                // Get references to all filter checkboxes within the same parent CheckboxList
                var filterCheckboxes = checkboxList.querySelectorAll('input[name="filter"]');

                // Loop through all filter checkboxes and update their checked state
                filterCheckboxes.forEach(function (checkbox) {
                    checkbox.checked = clearAllCheckbox.checked;
                });
            }
        });
    }

    // Add event listener to filter checkboxes
    document.querySelectorAll('input[name="filter"]').forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {
            // If any of the filter checkboxes is checked, uncheck the clear all checkbox if it exists
            var clearAllCheckbox = this.closest('.CheckboxList').querySelector('#show-all');

            if (clearAllCheckbox && clearAllCheckbox.checked) {
                clearAllCheckbox.checked = false;
            }
        });
    });
});
