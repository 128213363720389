import { fetcher } from '../utils/fetcher';

class AsyncSubmit {
  constructor(form) {
    this.form = form;
    this.successMessage = document.getElementById(form.dataset.success) || null;

    this.handleSubmit = this.handleSubmit.bind(this);

    this.bindHandlers();
  }

  bindHandlers() {
    this.form.addEventListener('submit' , this.handleSubmit)
  }

  async handleSubmit(e) {
    e.preventDefault();

    if(!this.form.checkValidity()) return;
    
    const url = this.form.action;
    const payload = new FormData(this.form);
    const response = await fetcher.post(url, payload);

    if( !response.error ) {
      this.form.setAttribute('hidden', 'hidden');
      this.successMessage.removeAttribute('hidden');
    }
  }
}

document.querySelectorAll('.js-AsyncSubmit').forEach(element => 
  new AsyncSubmit(element)
)
