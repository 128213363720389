class StarRating {
  constructor(element) {
    this.container = element;
    this.select = element.querySelector('select');
    this.stars = element.querySelectorAll('.js-Rating-star');

    this.handleStarClick = this.handleStarClick.bind(this);

    this.init();
  }

  init() {
    this.stars.forEach(star => {
      star.addEventListener('click', this.handleStarClick);
    })
  }

  handleStarClick(e) {
    const number = Number(e.currentTarget.value);
    
    this.select.value = number;
    this.select.dispatchEvent(new Event('input', { bubbles: true }));

    this.stars.forEach((star, index) => {
      if( index < number ) {
        star.classList.add('is-active');
      } else {
        star.classList.remove('is-active');
      }
    })
  }
}

document.querySelectorAll('.js-Rating').forEach(element => 
  new StarRating(element)
)