window.addEventListener('load', () => {
  function toggleConditionalFields() {
    let targetSelect = document.querySelector(
      '[data-target="conditionalSelect"]'
    );
    let targetFields = document.querySelector(
      '[data-target="conditionalFields"]'
    );
    let radioBlocked = document.getElementById('Blocked');
    let selectElement = document.getElementById('selectElement');

    if (targetSelect && radioBlocked?.checked) {
      targetSelect.style.display = 'grid';
      targetSelect.querySelectorAll('select').forEach(function (select) {
        select.required = true;
      });

      if (selectElement.value === 'other' || selectElement.value === '3') {
        targetFields.style.display = 'grid';
        targetFields.querySelectorAll('textarea').forEach(function (textarea) {
          if (textarea.getAttribute('data-req') === 'true') {
            textarea.required = true;
          } else {
            textarea.required = false;
          }
        });
      } else {
        targetFields.style.display = 'none';
        targetFields.querySelectorAll('textarea').forEach(function (textarea) {
          textarea.required = false;
        });
      }
    } else if (targetSelect) {
      targetSelect.style.display = 'none';
      targetSelect.querySelectorAll('select').forEach(function (select) {
        select.required = false;
        select.value = '';
      });

      targetFields.style.display = 'none';
      targetFields.querySelectorAll('input').forEach(function (input) {
        input.required = false;
        input.value = '';
      });
    }
  }

  document
    .getElementById('Blocked')
    ?.addEventListener('change', toggleConditionalFields);
  document
    .getElementById('Open')
    ?.addEventListener('change', toggleConditionalFields);
  document
    .getElementById('selectElement')
    ?.addEventListener('change', toggleConditionalFields);

  // Initially call to ensure correct state on page load
  toggleConditionalFields();

  document
    .getElementById('myForm')
    ?.addEventListener('submit', function (event) {
      let formFields = this.querySelectorAll('input:invalid');
      formFields.forEach(function (field) {
        field.classList.add('invalid');
      });
    });
});
