var buttons = document.querySelectorAll('.SearchBar-btn');
var closeDrawer = document.querySelectorAll('.Drawer-close');

// Function to toggle 'active' class on the button and dropdown after a delay
function toggleActiveWithDelay(button, dropdown) {
    // Toggle 'active' class on the button
    button.classList.toggle('active');
    // Toggle 'active' class on the dropdown
    dropdown.classList.toggle('active');
}


// Iterate over each button
buttons.forEach(function (button) {
    // Add click event listener
    button.addEventListener('click', function () {
        // Get the value of data-toggle attribute
        var targetId = this.getAttribute('data-toggle');
        // Get the ID of the corresponding dropdown
        var dropdownId = targetId;
        // Get the corresponding dropdown
        var dropdown = document.getElementById(dropdownId);

        // Check if the button and dropdown are already active
        var isButtonActive = this.classList.contains('active');

        // Check if there are any other active elements
        var anyOtherActiveElements = document.querySelector('.SearchBar-btn.active') !== null ||
            document.querySelector('.SearchBar-dropdown.active') !== null;

        // Remove 'active' class from other button and dropdown if they are active
        if (!isButtonActive) {
            var otherButtons = document.querySelectorAll('.SearchBar-btn:not([data-toggle="' + targetId + '"])');
            otherButtons.forEach(function (otherButton) {
                var otherDropdownId = otherButton.getAttribute('data-toggle');
                var otherDropdown = document.getElementById(otherDropdownId);
                if (otherDropdown.classList.contains('active')) {
                    // Remove 'active' class from other button and dropdown
                    otherButton.classList.remove('active');
                    otherDropdown.classList.remove('active');
                }
            });

            // If there are any other active elements, pause for 1 second
            if (anyOtherActiveElements) {
                setTimeout(function () {
                    // Toggle 'active' class on the button and dropdown after the delay
                    toggleActiveWithDelay(button, dropdown);
                }, 500);
            } else {
                // Toggle 'active' class on the button and dropdown immediately
                toggleActiveWithDelay(button, dropdown);
            }
        } else {
            // If the button is already active, toggle the dropdown immediately
            toggleActiveWithDelay(button, dropdown);
        }
    });
});

// Iterate over each button
closeDrawer.forEach(function (button) {
    // Add click event listener
    button.addEventListener('click', function () {
        // Get all active buttons and dropdowns
        var activeButtons = document.querySelectorAll('.SearchBar-btn.active');
        var activeDropdowns = document.querySelectorAll('.SearchBar-dropdown.active');

        // Remove 'active' class from all active buttons and dropdowns
        activeButtons.forEach(function(activeButton) {
            activeButton.classList.remove('active');
        });
        activeDropdowns.forEach(function(activeDropdown) {
            activeDropdown.classList.remove('active');
        });
    });
});

