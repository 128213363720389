function addPrefetch(kind, url, as) {
  const linkElem = document.createElement('link');
  linkElem.rel = kind;
  linkElem.href = url;
  if (as) {
      linkElem.as = as;
  }
  linkElem.crossorigin = true;
  document.head.appendChild(linkElem);
}

export default addPrefetch;