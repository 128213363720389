document.addEventListener('DOMContentLoaded', function () {
  window.applyTableTruncation = applyTableTruncation;
  applyTableTruncation();
});

export function applyTableTruncation() {
  var elements = document.querySelectorAll(
    '.Table tbody tr th, .Table tbody tr td, .multiple'
  );
  elements.forEach(function (el) {
    if (isTextOverflowing(el)) {
      // Text overflows, enable ellipsis and tooltip
      el.style.whiteSpace = 'nowrap';
      el.style.overflow = 'hidden';
      el.style.textOverflow = 'ellipsis';

      el.setAttribute('data-original-text', el.textContent || el.innerText); // Store original text
      el.classList.add('tooltip-enabled'); // Optional: for CSS styling

      el.addEventListener('mouseenter', showTooltip);
      el.addEventListener('mouseleave', removeTooltip);
    } else if (el.classList.contains('multiple')) {
      // If the element has 'multiple' class, show tooltip with data-multi attribute
      el.addEventListener('mouseenter', showMultiTooltip);
      el.addEventListener('mouseleave', removeTooltip);
    }
    // If text does not overflow, no additional actions are required
  });
}

function isTextOverflowing(element) {
  return element.scrollWidth > element.clientWidth;
}

function showTooltip(event) {
  var text = event.target.getAttribute('data-original-text');
  var tooltipDiv = document.createElement('div');
  tooltipDiv.textContent = text;
  tooltipDiv.className = 'custom-tooltip';

  document.body.appendChild(tooltipDiv);

  var coords = event.target.getBoundingClientRect();
  tooltipDiv.style.left = coords.left + window.scrollX + 'px';
  tooltipDiv.style.top =
    coords.top + window.scrollY - tooltipDiv.offsetHeight - 0 + 'px';
}

function showMultiTooltip(event) {
  var text = event.target.getAttribute('data-multi');
  var tooltipDiv = document.createElement('div');
  tooltipDiv.textContent = text;
  tooltipDiv.className = 'custom-tooltip';

  document.body.appendChild(tooltipDiv);

  var coords = event.target.getBoundingClientRect();
  tooltipDiv.style.left = coords.left + window.scrollX + 'px';
  tooltipDiv.style.top =
    coords.top + window.scrollY - tooltipDiv.offsetHeight - 0 + 'px';
}

function removeTooltip() {
  document.querySelectorAll('.custom-tooltip').forEach(function (tooltip) {
    tooltip.remove();
  });
}
