export default function datasetParser(dataset){
  const newDataset = { ...dataset };

  // Automatically parse string based numbers and objects into correct types
  Object.keys(newDataset).forEach(key => {
    try {
      newDataset[key] = JSON.parse(newDataset[key]);
    } catch {}
  });

  return newDataset;
}