import A11yDialog from 'a11y-dialog';

const modals = document.querySelectorAll('[data-a11y-dialog]');
const html = document.documentElement;
window.allyDialogs = [];

modals.forEach((element) => {
  const dialog = new A11yDialog(element);

  dialog
    .on('show', () => (html.style.overflowY = 'hidden'))
    .on('hide', () => (html.style.overflowY = ''));

  window.allyDialogs.push(dialog);
});

function get(id) {
  return window.allyDialogs.find(
    (dialog) => dialog.$el.dataset.a11yDialog === id
  );
}

function show(id) {
  get(id).show();
}

function hide(id) {
  const modal = get(id);
  if (modal) {
    modal.hide();
  } else {
    console.error('Modal not found with ID:', id);
  }
}

function destroy(id) {
  get(id).destroy();
  window.allyDialogs = window.allyDialogs.filter(
    (dialog) => dialog.$el.dataset.a11yDialog !== id
  );
}

export { get, show, hide, destroy };

export const modal = {
  get,
  show,
  hide,
  destroy,
  dialogs: window.allyDialogs,
};

document.addEventListener('DOMContentLoaded', function () {
  const openSecondaryModalButtons = document.querySelectorAll(
    '.OpenSecondaryModal'
  );
  const closeSecondaryModalButtons = document.querySelectorAll(
    '.CloseSecondaryModal'
  );

  openSecondaryModalButtons.forEach((button) => {
    button.addEventListener('click', function (event) {
      event.preventDefault(); //
      const modalId = this.dataset.modalId;
      const secondaryModal = get(modalId);
      if (secondaryModal) {
        show(modalId);
      } else {
        console.error('Modal not found.');
      }
    });
  });

  closeSecondaryModalButtons.forEach((button) => {
    button.addEventListener('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      const modalId = this.dataset.modalId;
      const secondaryModal = get(modalId);
      if (secondaryModal) {
        hide(modalId);
      } else {
        console.error('Modal not found.');
      }
    });
  });

  const switchModalButtons = document.querySelectorAll('.switchModalButton');

  switchModalButtons.forEach((button) => {
    button.addEventListener('click', function (event) {
      event.preventDefault();

      const targetModalId = this.dataset.switchModal;

      // Find the closest modal container of the clicked button
      const currentModalContainer = this.closest('[data-a11y-dialog]');

      if (currentModalContainer) {
        // Hide the current modal
        hide(currentModalContainer.dataset.a11yDialog);

        // Show the target modal
        show(targetModalId);
      } else {
        console.error('Modal container not found for the clicked button.');
      }
    });
  });
});
