if (module.hot) {
  module.hot.accept();
}

// Generic
import './modules/ToggleTarget';
import './modules/Modal';
import './modules/VideoPlayer';
import './modules/Tabs';
import './modules/FormValidator';
import './modules/AsyncSubmit';
import './modules/Accordion';

// Pre login 
import './modules/Navigation';

// Post login
import './modules/ContactsLoader';
import './modules/StarRating';
import './modules/FloatingFeedback';
import './modules/SearchButton';
import './modules/ModalCarousel';
import './modules/TableTruncation';
import './modules/ToggleSearchBar';
import './modules/CheckboxFilter';
import './modules/FormConditions';

// Admin
import './modules/UserForm';

// Make sure image are visible when printing
window.onbeforeprint = () => {
  const imgs = document.querySelectorAll('img');
  imgs.forEach(img => img.removeAttribute('loading') )
};

