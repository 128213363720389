import breakpoints from "../breakpoints";

class Tabs {
  constructor(element) {
    this.tablist = element.querySelector('[role="tablist"]');
    this.tabs = Array.from(element.querySelectorAll('[role="tab"]'));
    this.panels = Array.from(element.querySelectorAll('[role="tabpanel"]'));
    this.toggles = Array.from(element.querySelectorAll('.js-Tabs-toggle'));
    this.content = Array.from(element.querySelectorAll('.js-Tabs-content'));

    this.keys = {
      down: 40,
      end: 35,
      home: 36,
      left: 37,
      right: 39,
      up: 38
    };
  
    this.direction = {
      37: -1,
      38: -1,
      39: 1,
      40: 1
    };

    this.activationDelay = 300;
    this.breakpoint = breakpoints.xsmall;
    
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleTabKeydown = this.handleTabKeydown.bind(this);
    this.handleTabKeyup = this.handleTabKeyup.bind(this);
    this.handleTabFocus = this.handleTabFocus.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);

    this.init();
  }

  init() {
    const activeTab = this.tablist.querySelector(
      '[aria-selected="true"]'
    );

    // Scroll to active tabs if not first
    if (this.tabs.indexOf(activeTab) !== 0) {
      const tabOffset = activeTab.getBoundingClientRect().left;
      const tablistOffset = activeTab.offsetParent.getBoundingClientRect().left;
      this.tablist.scrollTo(tabOffset - tablistOffset, 0);
    }

    this.mq = window.matchMedia(`(min-width: ${this.breakpoint}px)`);
    this.handleMediaQueryChange(this.mq);

    this.bindHanders();
  }

  bindHanders() {
    for (const tab of this.tabs) {
      tab.addEventListener("click", this.handleTabClick);
      tab.addEventListener("keydown", this.handleTabKeydown);
      tab.addEventListener("keyup", this.handleTabKeyup);
      tab.addEventListener("focus", this.handleTabFocus);
    }

    for (const tab of this.toggles) {
      tab.addEventListener("click", this.handleToggleClick);
    }

    this.mq.addEventListener('change', this.handleMediaQueryChange);
  }

  handleMediaQueryChange(mq){
    if(mq.matches) {
      this.deactivateTabs();
      this.activateTab(0, false);
    } else {
      for (const content of this.content) {
        if(content.scrollHeight > 0) {
          content.style.setProperty('--max-height', `${content.scrollHeight}px`);
        }
      }
    }
  }

  handleTabClick(e) {
    const tab = e.target;
    const index = this.tabs.indexOf(tab);
    this.activateTab(index, false);
  }

  handleTabKeydown(e) {
    const key = e.keyCode;
    const keys = this.keys;

    switch (key) {
      case keys.end:
        e.preventDefault();
        // Activate last tab
        this.activateTab(this.tabs.length - 1);
        break;
      case keys.home:
        e.preventDefault();
        // Activate first tab
        this.activateTab(0);
        break;

      // Up and down are in keydown because we need to prevent page scroll
      case keys.up:
      case keys.down:
        e.preventDefault();
        this.determineOrientation(e);
        break;
    }
  }

  handleTabKeyup(e) {
    const key = e.keyCode;
    const keys = this.keys;

    switch (key) {
      case keys.left:
      case keys.right:
        this.determineOrientation(e);
        break;
    }
  }

  handleTabFocus(e) {
    const target = e.target;

    clearTimeout(this.focusTimeout);

    this.focusTimeout = setTimeout(() => {
      const focused = document.activeElement;

      if (target === focused) {
        const index = this.tabs.indexOf(target);
        this.activateTab(index, false);
      }
    }, this.activationDelay);
  }

  handleToggleClick(e) {
    const toggle = e.target;
    const index = this.toggles.indexOf(toggle);

    if( toggle.getAttribute("aria-expanded") === "true" ) {
      this.deactivateTab(index);
    } else {
      this.activateTab(index, false, false);
    }
  }

  activateTab(index, setFocus = true, deactivateOthers = true) {
    const tab = this.tabs[index];

    if( deactivateOthers ) {
      this.deactivateTabs();
    }
    
    tab.removeAttribute("tabindex");
    tab.setAttribute("aria-selected", "true");
    this.panels[index].setAttribute("aria-hidden", false);
    
    if(this.toggles[index]) {
      this.toggles[index].setAttribute("aria-expanded", true);
    }

    if(this.content[index]) {
      this.content[index].removeAttribute("hidden");
    }

    if (setFocus) {
      tab.focus();
    }
  }

  deactivateTab(index) {
    this.tabs[index].setAttribute("tabindex", "-1");
    this.tabs[index].setAttribute("aria-selected", "false");
    this.panels[index].setAttribute("aria-hidden", true);
    
    if(this.toggles[index]) {
      this.toggles[index].setAttribute("aria-expanded", false);
    }

    if(this.content[index]) {
      this.content[index].setAttribute("hidden", "hidden");
    }
  }

  deactivateTabs() {
    for (let index = 0; index <  this.tabs.length; index++) {
      const tab = this.tabs[index];

      tab.setAttribute("tabindex", "-1");
      tab.setAttribute("aria-selected", "false");

      this.panels[index].setAttribute("aria-hidden", true);

      if(this.toggles[index]) {
        this.toggles[index].setAttribute("aria-expanded", false);
      }

      if(this.content[index]) {
        this.content[index].setAttribute("hidden", "hidden");
      }
    }
  }

  switchTabOnArrowPress(e) {
    const pressed = e.keyCode;
    const keys = this.keys;
    const direction = this.direction[pressed];
    const tabs = this.tabs;

    if (direction) {
      const target = e.target;
      const index = this.tabs.indexOf(target);

      if (index !== undefined) {
        if (tabs[index + direction]) {
          tabs[index + direction].focus();
        } else if (pressed === keys.left || pressed === keys.up) {
          tabs[tabs.length - 1].focus();
        } else if (pressed === keys.right || pressed === keys.down) {
          tabs[0].focus();
        }
      }
    }
  };

  determineOrientation(e) {
    const key = e.keyCode;
    const keys = this.keys;
    const vertical =
      this.tablist.getAttribute("aria-orientation") === "vertical";
    let proceed = false;

    if (vertical) {
      if (key === keys.up || key === keys.down) {
        proceed = true;
      }
    } else {
      if (key === keys.left || key === keys.right) {
        proceed = true;
      }
    }

    if (proceed) {
      this.switchTabOnArrowPress(e);
    }
  };
}

document.querySelectorAll('.js-Tabs').forEach(element => 
  new Tabs(element)
);

export default Tabs;
