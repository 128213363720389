const form = document.querySelector('.js-UserForm');

if (form) {
  const accountType = form.querySelector(
    'input[name="accountType"]:checked'
  )?.value;
  const roles = form.querySelectorAll('[name="role"]');

  function setRoles(type) {
    roles.forEach((input) => {
      if (type === 'admin') {
        input.setAttribute('disabled', 'disabled');
        input.checked = true;
      } else {
        input.removeAttribute('disabled');
        input.checked = false;
      }
    });
  }

  form.addEventListener('input', (e) => {
    if (e.target.name === 'accountType') {
      setRoles(e.target.value);
    }
  });

  setRoles(accountType);
}
