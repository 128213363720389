export const fetcher = {
  get,
  post,
  put,
  delete: _delete,
  send
};

async function get(url, options = {}) {
  const requestOptions = {
    ...options,
    method: 'GET'
  };

  return await send(url, requestOptions);
}

async function post(url, body, options = {}) {
  const requestOptions = {
    ...options,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    method: 'POST',
  };

  return await send(url, requestOptions);
}

async function put(url, body, options = {}) {
  const requestOptions = {
    ...options,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return await send(url, requestOptions);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, options = {}) {
  const requestOptions = {
    ...options,
    method: 'DELETE'
  };

  return await send(url, requestOptions);
}

// helper functions

async function send(url, requestOptions) {
  try {
    const response = await fetch(url, requestOptions);
    return await handleResponse(response);
  } catch (error) {
    return handleNetworkError(error);
  }
}

async function handleResponse(response) {
  if (!response.ok) {
    const error = {
      status: response.status,
      statusText: response.statusText,
    };

    throw error;
  }

  const text = await response.text();

  try {
    const data = JSON.parse(text);
    return data;
  } catch(error) {
    return text;
  }
}

function handleNetworkError(error) {
  console.error('There was an error!', error);

  return {
    error: {
      status: error.status || null,
      message: error.statusText || error
    }
  };
}