import merge from 'lodash/merge';
class FormValidator {
  constructor(form, config) {
    this.settings = merge({}, FormValidator.DEFAULTS, config);
    this.form = form;
    form.validator = this;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  initialize() {
    this.form.noValidate = true;
    this.form.addEventListener('submit', this.handleSubmit);
    this.form.addEventListener('input', this.handleInput);

    // Apply rules
    Array.from(this.form.elements).forEach((element) => {
      const pattern = this.settings.rules[element.name]?.pattern;

      if (!element.getAttribute('pattern') && pattern) {
        element.setAttribute('pattern', pattern);
      }
    });
  }

  reset() {
    Array.from(this.form.elements).forEach((field) => {
      if (field.type !== 'submit') {
        field.setCustomValidity('');
      }
    });
  }

  handleSubmit(e) {
    this.reset();
    this.validateForm();
    if (!this.form.checkValidity()) {
      e.preventDefault();

      Array.from(this.form.elements).forEach((field) => {
        if (field.type !== 'submit') {
          this.validate(field);
        }
      });

      // Set focus to first invalid field
      this.form.querySelector(':invalid').focus();
    }
  }

  handleInput(e) {
    e.target.setCustomValidity('');
    const fieldset = e.target.closest('fieldset');

    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      this.validateInputList(e.target);
    }

    if (fieldset) {
      this.validateFieldSet(fieldset);
    }

    this.validate(e.target);
  }

  validateForm() {
    Array.from(this.form.elements).forEach((field) => {
      if (field.type !== 'submit') {
        this.validate(field);
      }
    });

    return this.form.querySelectorAll(':invalid').length === 0;
  }

  validate(field) {
    if (field.nodeName === 'FIELDSET') {
      this.validateFieldSet(field);
      return;
    }

    for (const key in field.validity) {
      const defaultMessages = this.settings.defaults.messages;
      const messages = this.settings.rules[field.name]?.messages || {};

      if (field.validity[key]) {
        const message =
          messages[key] || defaultMessages[key] || field.validationMessage;

        if (message) {
          field.setCustomValidity(message);
          break;
        } else {
          field.setCustomValidity('');
        }
      }
    }

    if (field.validity.valid) {
      this.hideError(field);
    } else {
      this.showError(field);
    }

    field.setAttribute('aria-invalid', !field.validity.valid);
  }

  validateInputList(input) {
    const inputs = Array.from(
      this.form.querySelectorAll(`[name="${input.name}"]`)
    );
    const hasChecked = !!inputs.filter((input) => input.checked).length;

    inputs.forEach((input) => {
      if (hasChecked) {
        input.removeAttribute('required');
        input.removeAttribute('aria-invalid');
      } else {
        input.setAttribute('required', '');
        input.setAttribute('aria-invalid', true);
      }
    });
  }

  validateFieldSet(fieldset) {
    fieldset.setAttribute(
      'aria-invalid',
      fieldset.querySelectorAll(':invalid').length !== 0
    );
  }

  showError(field) {
    const errorMessage = this.getValidationMessageOutput(field);

    if (errorMessage) {
      errorMessage.innerText = field.validationMessage;
      errorMessage.removeAttribute('hidden');
    }
  }

  hideError(field) {
    const errorMessage = this.getValidationMessageOutput(field);

    if (errorMessage) {
      errorMessage.innerText = '';
      errorMessage.setAttribute('hidden', true);
    }
  }

  getValidationMessageOutput(field) {
    if (field.type === 'checkbox') {
      return field
        .closest('.CheckboxList')
        ?.querySelector('.js-Validate-message');
    } else {
      return field.parentElement.querySelector('.js-Validate-message');
    }
  }
}

FormValidator.DEFAULTS = {
  defaults: {
    messages: {
      valueMissing: '',
      typeMismatch: '',
      patternMismatch: '',
      tooLong: '',
      tooShort: '',
      rangeUnderflow: '',
      rangeOverflow: '',
      stepMismatch: '',
      customError: '',
    },
  },
  rules: {
    email: {
      pattern: `^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$`,
      messages: {
        typeMismatch: 'Please enter an email address.',
        patternMismatch: 'Please enter an email address.',
      },
    },
  },
};

document.querySelectorAll('.js-Validate').forEach((form) => {
  const validator = new FormValidator(form);
  validator.initialize();
});
