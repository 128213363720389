import Glide from '@glidejs/glide'
import { modal } from './Modal';

window.addEventListener('load', () => {  
  document.querySelectorAll('.js-ModalCarousel').forEach( element => {
    const glide = new Glide( element, {
      type: 'carousel',
      classes: {
        direction: {
          ltr: 'ModalCarousel--ltr',
          rtl: 'ModalCarousel--rtl'
        },
        type: {
          slider: 'ModalCarousel--slider',
          carousel: 'ModalCarousel--carousel'
        },
        swipeable: 'ModalCarousel--swipeable',
        dragging: 'ModalCarousel--dragging',
        nav: {
          active: 'ModalCarousel-navItem--active'
        },
        slide: {
          active: 'ModalCarousel-slide--active',
          clone: 'ModalCarousel-slide--clone'
        },
        arrow: {
          disabled: 'ModalCarousel-arrow--disabled'
        }
      }
    }).mount();

    document.addEventListener('click', e => {
      const trigger = e.target.closest('[data-modal-carousel-trigger]');

      if ( trigger ) {
        e.preventDefault();
        
        const { modalCarouselTrigger, modalCarouselIndex } = trigger.dataset;

        modal.show( modalCarouselTrigger );
        glide.update({ startAt: Number( modalCarouselIndex ) });
      }
    })
  })
});