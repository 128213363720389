
import breakpoints from '../breakpoints';
import { fetcher } from '../utils/fetcher';

class ContactsLoader {
  constructor(element, options) {
    this.settings = {
      breakpoint: breakpoints.large,
      ...options
    };
    this.container = element;
    this.target = null;
    this.links = null;

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleTargetClick = this.handleTargetClick.bind(this);

    this.init();
  }

  init() {
    this.target = document.getElementById(this.settings.target);
    this.links = this.container.querySelectorAll('a');
    this.mq = window.matchMedia(`(min-width: ${this.settings.breakpoint}px)`);

    this.bindHandlers();
    
    this.links[0].classList.add('is-active');
    this.getFragment(this.links[0].href);
  }

  bindHandlers(){
    this.container.addEventListener('click', e => {
      if(e.target.closest('a')) {
        this.handleLinkClick(e);
      }
    })
    
    this.target.addEventListener('click', this.handleTargetClick);
  }

  handleLinkClick(e){
    e.preventDefault();
    this.getFragment(e.target.href);
    this.links.forEach(link => link.classList.remove('is-active'));
    e.target.classList.add('is-active');
    this.activateTarget();
  }

  handleTargetClick(e){
    if(e.target.closest('[data-action="back"]')) {
      this.deactivateTarget();
    }
  }

  activateTarget() {
    this.target.classList.add('is-active');

    if(!this.mq.matches) {
      document.body.classList.add('u-scroll-lock');
    }
  }
  
  deactivateTarget() {
    this.target.classList.remove('is-active');

    if(!this.mq.matches) {
      document.body.classList.remove('u-scroll-lock');
    }
  }

  async getFragment(url) {
    const placeholder = this.target.querySelector('[data-placeholder]') || this.target;

    placeholder.innerHTML = '';

    const html = await fetcher.get(url);
    
    placeholder.innerHTML = html;
  }
}


document.querySelectorAll('.js-FragmentLoader').forEach(element => {
  new ContactsLoader(element, element.dataset);
})