import ToggleTarget from './ToggleTarget';
import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';
import breakpoints from '../breakpoints';
import objectParser from '../utils/object-parser';

class Accordion {
    constructor( container, options ){
        this.settings = merge( {}, Accordion.DEFAULTS, options );
        this.container = container;
        this.items = this.container.querySelectorAll( this.settings.selectors.items );
        this.instances = [];

        this.bindHandlers();
    }

    bindHandlers(){
        if( this.settings.breakpoint ) {
            const { breakpoint, mediaQuery } = this.settings;
            const width = mediaQuery === 'max-width' ? breakpoints[breakpoint] - 1 : breakpoints[breakpoint];
            
            this.mq = window.matchMedia(`(${mediaQuery}: ${width}px)`);
            this.mq.addEventListener('change', this.handleMediaQueryChange.bind(this) );

            this.handleMediaQueryChange();
        } else {
            this.initialise();
        }        
    }

    handleMediaQueryChange() {
        if( this.mq.matches ) {
            this.initialise();
        } else {
            this.destroy();
        }
    }

    initialise(){    
        const { selectors } = this.settings;
        
        this.items.forEach( item => {
            const toggle = item.querySelector( selectors.toggle );
            const panel = item.querySelector( selectors.panel );
            const isExpanded = toggle.getAttribute('aria-expanded') === 'true';

            panel.id = panel.id || uniqueId('panel-');

            toggle.setAttribute('aria-expanded', isExpanded);
            toggle.setAttribute('aria-controls', panel.id);
            
            if( toggle.getAttribute('aria-expanded') === 'false' ) {
                panel.setAttribute('hidden', true);
            }

            this.instances.push( new ToggleTarget( toggle ) );
        });    
    }
    
    destroy() {    
        const { selectors } = this.settings;

        this.instances.forEach( instance => instance.destroy() );
        this.instances = [];

        this.items.forEach( item => {
            const toggle = item.querySelector( selectors.toggle );
            const panel = item.querySelector( selectors.panel );
    
            toggle.removeAttribute('aria-expanded');
            toggle.removeAttribute('aria-controls');
            
            panel.removeAttribute('hidden');
        });
    }
};

Accordion.DEFAULTS = {
    breakpoint: null,
    mediaQuery: 'max-width',
    selectors: {
        container: '.js-Accordion',
        items: '.js-Accordion-item',
        toggle: '.js-Accordion-toggle',
        panel: '.js-Accordion-panel'
    }
};

window.addEventListener('load', (event) => {
    document.querySelectorAll( Accordion.DEFAULTS.selectors.container )
        .forEach( element => {
            new Accordion( element, objectParser(element.dataset) )
        });
});

export default Accordion;