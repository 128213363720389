import A11yDialog from 'a11y-dialog';
import merge from 'lodash/merge';
import datasetParser from '../utils/dataset-parser';
import { fetcher } from '../utils/fetcher';
import { generateEmbed, warmConnections } from "../utils/vimeo";

function generateDialog(id, className, content) {
  const dialogTemplate = `
    <div
      class="${className}"
      id="${id}"
      aria-labelledby="${id}-title"
      aria-hidden="true"
    >
      <div class="${className}-overlay" data-a11y-dialog-hide></div>

      <div class="${className}-dialog" role="document">
        <button class="${className}-close" type="button" data-a11y-dialog-hide aria-label="Close dialog">
          &times;
        </button>
        <h1 class="${className}-title" id="${id}-title">Your dialog title</h1>
        <div class="${className}-content">
          ${content}
        </div>
      </div>
    </div>
  `;
  const parser = new DOMParser();
  return parser.parseFromString(dialogTemplate, "text/html");
}

class VideoPlayer {
  constructor( container, options ){
    this.container = container;
    this.settings = merge( {}, VideoPlayer.DEFAULTS, options );
    this.player = null;
    this.handlePlayClick = this.handlePlayClick.bind(this);

    this.init();
  }

  async init(){
    const { classNames, btnText } = this.settings;
    this.data = await fetcher.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.settings.videoId}`);

    this.container.innerHTML = `
      <h2 class="${classNames.title}">${this.data.title}</h2>
      <img class="${classNames.thumb}" src="${this.data.thumbnail_url}" alt="${this.data.title}" loading="lazy">
      <button class="${classNames.btn}" type="button" aria-label="${btnText}"></button>
    `;

    this.container.querySelector('button')
      .addEventListener('click', this.handlePlayClick );

    this.container.addEventListener('pointerover', warmConnections, {
      once: true
    });
  }

  showModal(){
    const dialogHtml = generateDialog(
      'video', 
      'VideoModal',
      this.player.outerHTML
    ).querySelector('#video');

    document.body.appendChild(dialogHtml);

    const dialog = new A11yDialog(dialogHtml);
    
    dialog.on('hide', function (element, event) {
      dialog.destroy();
      dialogHtml.remove();
    });

    dialog.show();
  }

  handlePlayClick(){
    const { videoId, isInline, playerParameters } = this.settings;

    if(!this.player) {
      this.player = generateEmbed(videoId, playerParameters);
    }
    
    if( isInline ) {
      this.container.replaceChildren(this.player);
    } else {
      this.showModal();
    }
  }
}

VideoPlayer.DEFAULTS = {
  videoId: null,
  isInline: true,
  btnText: 'Watch video',
  classNames: {
    title: 'VideoPlayer-title',
    thumb: 'VideoPlayer-thumb',
    btn: 'VideoPlayer-btn'
  },
  // See https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters
  playerParameters: {
    color: '4dc3b3',
    dnt: true,
    byline: false
  }
};

document.querySelectorAll( '.js-VideoPlayer' )
  .forEach( element => new VideoPlayer( element, datasetParser(element.dataset) ) );

export default VideoPlayer;
