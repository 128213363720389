import { on, off } from 'delegated-events';
import focusLock from 'dom-focus-lock';
import breakpoints from '../breakpoints';
import datasetParser from '../utils/dataset-parser';

class ToggleTarget {
    constructor( toggle, options ){
        const controls = toggle.getAttribute('aria-controls'); 

        this.settings = { ...ToggleTarget.DEFAULTS, ...options };
        this.toggle = toggle;
        this.target = document.getElementById(controls);        
        this.mq = null;
        this.origExpanded = 
            this.toggle.getAttribute('aria-expanded') === 'true' || 
            this.target.getAttribute('hidden') === null;

        this.isExpanded = this.settings.isExpanded;
        this.isInitialised = false;

        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this)
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);

        this.init();
    }

    init() {
        const { mediaQuery, breakpoint } = this.settings;

        this.target.style.setProperty('--max-height', `${this.target.scrollHeight}px`);

        if( breakpoint ) {
            this.mq = window.matchMedia(
                `(${mediaQuery}: ${mediaQuery === 'max-width' ? breakpoints[breakpoint] - 1 : breakpoints[breakpoint]}px)`
            );
            this.mq.addEventListener("change", this.handleMediaQueryChange);
            this.handleMediaQueryChange(this.mq);
        } else {
            this.bindHandlers();
        }
    }

    bindHandlers(){
        this.isInitialised = true;
        this.isExpanded ? this.show() : this.hide();

        this.toggle.addEventListener('click', this.handleToggleClick );
        this.target.addEventListener('keydown', this.handleKeydown );
        
        if( this.settings.closeOnOutsideClick ) {
            document.addEventListener('click', this.handleDocumentClick );
        }

        on('click', '[data-action="close"]', this.handleCloseClick );
    }

    show() {
        this.toggle.setAttribute('aria-expanded', true);
        this.target.removeAttribute('hidden');
        this.target.classList.add('is-expanded');
        this.isExpanded = true;

        if( this.settings.focusLock ) {
            focusLock.on( this.target );
        }

        if( this.settings.bodyClass ) {
            document.body.classList.add( this.settings.bodyClass );
        }

        this.settings.onShow(this);
    }
    
    hide(){
        this.toggle.setAttribute('aria-expanded', false);
        this.target.setAttribute('hidden', true);
        this.target.classList.remove('is-expanded');
        this.isExpanded = false;

        if( this.settings.focusLock ) {
            focusLock.off( this.target );
        }

        if( this.settings.bodyClass ) {
            document.body.classList.remove( this.settings.bodyClass );
        }

        this.settings.onHide(this);
    }

    handleMediaQueryChange(mq) {
        if(mq.matches) {
            this.bindHandlers();
        } else {
            this.destroy(false);
        }
    }

    handleCloseClick(e){
        if( !this.target.contains(e.target ) ) return;
        this.hide();
        this.toggle.focus();
    }
    
    handleKeydown(e) {
        if( e.keyCode !== 27 ) return;

        this.toggle.focus();
        this.hide();
    }

    handleDocumentClick(e) {
        const inToggle = this.toggle.contains( e.target );
        const inTarget = this.target.contains( e.target );

        if( !inToggle && !inTarget && this.isExpanded ) {
            this.hide();
        }
    }
    
    handleToggleClick(e){
        e.preventDefault();

        if( !this.isExpanded ) {
            this.show();
        } else {
            this.hide();
        }
    }

    destroy(isFull = true){
        if( !this.isInitialised ) return;
  
        this.toggle.removeEventListener('click', this.handleToggleClick );
        this.target.removeEventListener('keydown', this.handleKeydown );
        
        if( this.settings.closeOnOutsideClick ) {
            document.removeEventListener('click', this.handleDocumentClick );
        }

        off('click', '[data-action="close"]', this.handleCloseClick );

        this.isExpanded = this.settings.isExpanded;
        this.toggle.setAttribute('aria-expanded', this.origExpanded);
        this.target.classList.remove('is-expanded');
        this.origExpanded ? this.target.removeAttribute('hidden') : this.target.setAttribute('hidden', true);

        document.body.classList.remove( this.settings.bodyClass );

        if( this.settings.focusLock ) {
            focusLock.off( this.target );
        }
       
        if( isFull ) {
            this.isInitialised = false;
            this.toggle = null;
            this.target = null;
            this.settings = null;
        }

        if( this.mq && isFull ) {
            this.mq.removeEventListener("change", this.handleMediaQueryChange);
            this.mq = null;
        }
    }
}

ToggleTarget.DEFAULTS = {
    breakpoint: null,
    mediaQuery: 'min-width',
    closeOnOutsideClick: false,
    focusLock: false,
    bodyClass: null,
    isExpanded: null,
    onShow: () => {},
    onHide: () => {}
};

document.querySelectorAll('.js-Toggle').forEach(element => 
    new ToggleTarget( element, datasetParser(element.dataset) )
);

export default ToggleTarget;